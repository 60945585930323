import React from 'react'
import { useNavigate } from 'react-router-dom'

export const Login = () => {


    const navigate = useNavigate();


    const setDemoTokenStorage = async (e) => {

    
        let confirmarSesion = await window.confirm('Desea Iniciar sesion?')


        // SIMULACRO TRAYENDO DATA FROM API-REST
        if (confirmarSesion) {

            localStorage.removeItem('AuthUserToken');
            const AuthUserToken = [{
                name: 'Braulio 0002',
                token: '12345kg77',
                logged: true
            }]

            localStorage.setItem('AuthUserToken', JSON.stringify(AuthUserToken));
            navigate('/home')
            console.log('welcome');

        } else {
            e.preventDefault();
            console.log('cancelaste');
        }
    }






    return (
        <form style={{ textAlign: 'center', margin: '50px' }} onSubmit={setDemoTokenStorage}>

            <h3>Login Page :</h3><br />
            <input className='inputsLogin' type={'text'} placeholder='E-mail' /> <br /><br />
            <input className='inputsLogin' type='text' placeholder='Password' /> <br /><br />
            <button type='submit' className='btnLogIn'>Log In</button>

        </form>
    )
}
