import { AppRouter } from "./components/routers/AppRouter";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Fragment } from "react";
import { IndexFlex } from "./components/FlexBox/IndexFlex";
import { IndexHome } from "./components/FlexBox/TestMaquetando/IndexHome";
import { IndexPage } from "./components/IndexPage/IndexPage";
import { AuthContext, sliderIndexContext } from "./store";
import { ValidatingAccount } from "./components/pages/ValidatingAccount/ValidatingAccount";



function App() {





  return (

    <AuthContext.Provider value={sliderIndexContext}>
      <AppRouter />
    </AuthContext.Provider>

  );
}

export default App;
