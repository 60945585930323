import { IndexPage } from "../IndexPage/IndexPage";
import { Login } from "../pages/Login";
import { ValidatingAccount } from "../pages/ValidatingAccount/ValidatingAccount";




export const ListOfRoutes =
[
    // PUBLIC
    {
        path: "/login",
        Component: <Login />,
        authRequired: false
    },
    {
        path: "/validating-account/:token_access",
        Component: <ValidatingAccount />,
        authRequired: false
    },



    // PRIVATE
    {
        path: "/*",
        Component: <Login />,
        authRequired: true
    },


]
