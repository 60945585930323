import { createContext } from "react";


// 
 const sliderIndexContext =  {
    indexSlider: 1
}







// Context
 const AuthContext = createContext(sliderIndexContext.indexSlider);

 export {AuthContext, sliderIndexContext};
