import React, { useEffect, useState } from 'react'
import miturBacground from "../../images/gogym/icono.png";
import dominicanLogo from "../../images/gogym/icono.png";
import { SliderIndex } from '../SliderIndex/index'
import {
    BodyGrid,
    ContainerGrid,
    DivGrid,
    DivThreeGrid,
    DivUsuariosRegistradosGrid,
    SpanDescripGrid,
    A_Grid,
    DivCentralGrid,
    ImgDominicanLogoGrid,
    MenuDesplegableGrid,
    UlGrid, LiGrid,
    SpanDescripcionHeaderGrid,
    ImgContainerGrid,
    ImgDivGrid,
    HeaderGrid,
    FooterGrid,
    DivArticleGrid,
    BtnIniciarSesionGrid
} from './IndexPageElements'


// ICONS
import iconCliente from '../../images/icons/img/iconCliente.png'
import icons_Search from '../../images/icons/img/lupaBuscar.svg'
import icons_MenuDesplegar from '../../images/icons/img/menu_white.svg'

// IMAGES SLIDERS
import puente1 from '../../images/slider-mitur/puente1.jpeg'
import puente2 from '../../images/slider-mitur/puente2.jpeg'
import puente3 from '../../images/slider-mitur/puente3.jpeg'


// slider opcion 1
import SimpleImageSlider from "react-simple-image-slider";

// slider opcion 2
//sileder:    react reveal


// PROPIEDADES FROM STYLED
import { H1Icon, IconsStyle, PsmallIcon } from '../../images/icons/IconsElements';
import { Carrucer } from './Carrucer';
import { CarrucerConFlechas } from './CarrucerConFlechas';



export const IndexPage = () => {


    const [imgSliderActual, setImgSliderActual] = useState('')
    const [indexImgState, setIndexImgState] = useState(0);

    const images = [
        { url: `${puente1}` },
        { url: `${puente2}` },
        { url: `${puente3}` },
    ];


    const ChangeImgSlider = (datoIndex = 0) => {
        let indexImg = images.length;
        let index = datoIndex;

        setInterval(() => {
            if (index == indexImg) {
                index = 0;
            }
            setIndexImgState(index);
            setImgSliderActual(images[index].url);
            index++;
        }, 6000);
    }


    // iniciamoso contador
    useEffect(() => {
        // ChangeImgSlider();
    }, [])


    const styleSlider = {
        width: '100%',
        height: '100vh',

    }


    return (

        <ContainerGrid>

            {/* HEADER */}
            <HeaderGrid>
                <SpanDescripGrid size="24" color="white">
                    HOME
                    {/* <A_Grid size="12" color="#ed5f30"> www.sisboa.com </A_Grid> */}
                </SpanDescripGrid>

                {/* <SpanDescripcionHeaderGrid>Link Visita</SpanDescripcionHeaderGrid> */}

                <ImgContainerGrid>
                    <ImgDominicanLogoGrid  src={dominicanLogo} />
                </ImgContainerGrid>

            </HeaderGrid>







            {/* CONTENIDO miturBacground */}
            <DivCentralGrid style={{
                backgroundImage: "url(" + `"${imgSliderActual}"` + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>

                {/* <CarrucerConFlechas /> */}
      





                <DivGrid alineado="center" margR="100" margT="50">

                    <MenuDesplegableGrid>
                        <IconsStyle src={icons_MenuDesplegar}></IconsStyle>
                    </MenuDesplegableGrid>

                    


                    {/* Slider */}
                    {/* <SliderIndex /> */}

                </DivGrid>
            </DivCentralGrid>


            {/* ICONOS REGISTRO USUARIOS */}

            {/* FOOTER */}
            <FooterGrid>Footer</FooterGrid>
        </ContainerGrid>

    )
}
