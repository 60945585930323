import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import iconogogym from '../../../images/gogym/icono.png'

import successActivation from '../../../images/icons/img/success-activation.png'
import errorActivation from '../../../images/icons/img/error-activation.png'
import { useQuery, useQueryClient } from 'react-query'
import { getTokenValidationAccount } from '../../../Api/peticiones_server'




import styled from 'styled-components'

const Header = styled.div`
    padding: 30px;
    background-color: #041C32;
    text-align: center;
`
const Texto = styled.span`
    font-size: 30px;
    color: white;
`
const ContainerImg = styled.div`
float: right;


`





export const ValidatingAccount = () => {

    const { token_access: param_token_access } = useParams();
    // const [estadoValidacion, setestadoValidacion] = useState(0);
    const [estadoValidacion, setestadoValidacion] = useState({
        estadoValidacion: 0,
        message: "",
    });
    const navitage = useNavigate()

    useEffect(() => {

        if (param_token_access) {

            const tokenOfActivation = {
                "token": param_token_access
            };

            getTokenValidationAccount(tokenOfActivation).then(data => {

                data.success == true ? setestadoValidacion({estadoValidacion: 1, message: data.msg }) :
                data.success == false ? setestadoValidacion({estadoValidacion: 2, message: data.msg }) :
                navitage('/login')

            })

        } else {
            navitage('/login')
        }





    }, [])

    // const queryClient = useQueryClient()
    // const { data: estadoValidacion, error, isLoading, isSuccess, status }  = useQuery('estatusValidation', getTokenValidationAccount("Mundito"))










    return (

        <div >


            {/* <div className='col-12'>
                <nav className="navbar navbar-dark bg-secondary pb-4 ">
                    <div className="container-fluid pb-2" >
                        <a className="navbar-brand">
                            GoGym

                            <div>
                                <img width="60" src={iconogogym} alt='LOGO' />
                            </div>


                        </a>
                    </div>
                </nav>
            </div> */}

            <Header>

                <Texto>GoGym</Texto>

                <ContainerImg>
                    <img width="60" src={iconogogym} alt='LOGO' />
                </ContainerImg>

            </Header>


            {estadoValidacion.estadoValidacion == 1 ?

                // SUCCESS
                <div className='ValidationAccount'>
                    <div className="card Centered " >
                        <div className="card-body">
                            <img width="100" src={successActivation} alt='SUCCESS-ACTIVATION' /> <br />  <br />
                            <h5 className="card-title">{estadoValidacion.message} :)</h5>
                            {/* <p className="card-text">{estadoValidacion.message}</p> */}
                            <button onClick={() => console.log('CUENTA ACTIVADA!')} style={{ color: "white" }} className="btn btn-info ">
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>

                :
                estadoValidacion.estadoValidacion == 2 ?

                    //ERROR
                    <div className='ValidationAccount'>
                        <div className="card Centered " >
                            <div className="card-body">
                                <img width="100" src={errorActivation} alt='SUCCESS-ACTIVATION' /> <br />  <br />
                                <h5 className="card-title"> {estadoValidacion.message}! :(</h5>
                                {/* <p className="card-text"> <br /> {estadoValidacion.message}</p> */}
                                <button onClick={() => console.log('CUENTA ACTIVADA!')} style={{ color: "white" }} className="btn btn-info ">
                                    Registrarme
                                </button>
                            </div>
                        </div>
                    </div>

                    :

                    //NADA SUPLIDO
                    navitage('/login')




            }



        </div>


    )
}
